import { SideNavInterface } from '../../shared/interfaces/side-nav.type';

export const MENU_CONFIG: SideNavInterface[] = [
    {
        path: '/dashboard',
        title: 'Dashboard',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'inbox',
        submenu: []
    },
    // {
    //     path: '/documentos-pendentes-validacao',
    //     title: 'Processos Pendentes',
    //     iconType: 'nzIcon',
    //     iconTheme: 'outline',
    //     icon: 'check',
    //     submenu: []
    // },
    {
        path: '/consultar',
        title: 'Consultar',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'search',
        submenu: []
    }
]    