import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutenticacaoRouteService } from './core/guards/autenticacao-route.service';
import { CommonLayoutComponent } from "./core/layouts/common-layout/common-layout.component";
import { CommonLayout_ROUTES } from "./core/routes/common-layout.routes";

const appRoutes: Routes = [
    {
        path: '',
        component: CommonLayoutComponent,
        children: CommonLayout_ROUTES,
        canActivate: [AutenticacaoRouteService]
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            useHash: false,
            scrollPositionRestoration: 'enabled'
        })
    ],
    exports: [
        RouterModule
    ]
})

export class AppRoutingModule {
}