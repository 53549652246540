import { Routes } from '@angular/router';


export const CommonLayout_ROUTES: Routes = [
    {
        path: 'dashboard',
        loadChildren: () => import('../../module/dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
        path: 'documentos-pendentes-validacao',
        loadChildren: () => 
            import('../../module/documentos-pendentes-validacao/documentos-pendentes-validacao.module')
                .then(m => m.DocumentosPendentesValidacaoModule)
    },
    {
        path: 'consultar',
        loadChildren: () => 
            import('../../module/consultar/consultar.module')
                .then(m => m.ConsultarModule)
    },
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
    }
];